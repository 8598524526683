import React from 'react';
import Layout from 'gatsby-theme-carbon/src/components/Layout';
import { Link } from 'gatsby';
import {
    container,
    fourOhFour,
    paragraph,
    heading,
} from 'gatsby-theme-carbon/src/components/FourOhFour/FourOhFour.module.scss';

const FourOhFour = () => (
    <Layout homepage>
        <div className={container}>
            <h2 className={heading}>Oops, it seems that we’ve gotten you lost....</h2>
            <p className={paragraph}>
                We can’t seem to find the page you were looking for. Go back to the <Link to="/">home page</Link>
            </p>
            <p>

            </p>

            <span className={fourOhFour}>404</span>
        </div>
    </Layout>
);

export default FourOhFour;
