import React from 'react'

const LocalImage = ({ url, imageSRC, altTxt }) => {
    return (
        <a href={url} target="_blank" >
            <img src={imageSRC} alt={altTxt} width="56" height="56" />
        </a>
    )
}

export default LocalImage