import React from "react";

function Contact() {
  return (
    <div className="FormContainer">
      <form action={process.env.CONTACT_FORM} method="POST">
        <div class="row">
          <div class="col-15">
            <label for="name">Your Name</label>
          </div>
          <div class="col-75">
            <input type="text" id="name" name="name" placeholder="Your name.." required />
          </div>
        </div>
        <div class="row">
          <div class="col-15">
            <label for="email">Email</label>
          </div>
          <div class="col-75">
            <input type="text" id="email" name="email" placeholder="Your email..." required />
          </div>
        </div>
        <div class="row">
          <div class="col-15">
            <label for="message">Message</label>
          </div>
          <div class="col-75">
            <textarea id="message" name="message" placeholder="Message.." style={{ height: "200px" }} required ></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-90">
            <input type="submit" value="Submit" />
            { /*onClick={() => { window.location.href = "/success"; }}*/}
          </div>
        </div>
      </form>
    </div>
  );
}

export default Contact;