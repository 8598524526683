import React from 'react';
import { HomepageBanner, HomepageCallout } from 'gatsby-theme-carbon';
import { calloutLink } from './Homepage.module.scss';
//import Homepage from 'gatsby-theme-carbon/src/component/Homepage';
import BannerGif from '../pages/components/images/home.gif';



import HomepageTemplate from 'gatsby-theme-carbon/src/templates/Homepage';


const FirstLeftText = () => <p>Managed services approach designing, implementing, and integrating AI solutions within your organization, adding value to your business within budget. We bring 20+ years of industry experience in applied data science projects.
</p>;

const FirstRightText = () => (
  <p> </p>
);

const SecondLeftText = () => <p>Callout component</p>;

const SecondRightText = () => (
  <p>
    You can also not use these components at all by not providing the callout
    props to the template or writing your own template.
    <a
      className={calloutLink}
      href="https://github.com/carbon-design-system/gatsby-theme-carbon/blob/main/packages/example/src/gatsby-theme-carbon/templates/Homepage.js">
      Homepage source →
    </a>
  </p>
);

const BannerText = () => <h1>Merging AI, Project Management and QA Practices </h1>;

const customProps = {
  Banner: <HomepageBanner renderText={BannerText} image={BannerGif} />,
  /*FirstCallout: (
    <HomepageCallout
      backgroundColor="#030303"
      color="white"
      leftText={FirstLeftText}
      rightText={FirstRightText}
    />
  ),/*
  SecondCallout: (
    <HomepageCallout
      leftText={SecondLeftText}
      rightText={SecondRightText}
      color="white"
      backgroundColor="#061f80"
    />
  ),*/
};

// spreading the original props gives us props.children (mdx content)

function ShadowedHomepage(props) {
  console.log(props)
  return <HomepageTemplate {...props} {...customProps} />;
}

export default ShadowedHomepage;