import { UserExperienceDesign, BugVirusMalware, AppDeveloper, PlanningAnalytics, CloudUpload, Speedometer } from '@carbon/pictograms-react';
import * as React from 'react';
export default {
  UserExperienceDesign,
  BugVirusMalware,
  AppDeveloper,
  PlanningAnalytics,
  CloudUpload,
  Speedometer,
  React
};