import LocalImage from "../../../../src/pages/services/LocalImage";
import { DesignAndDevelopment_02, BugVirusMalware, SupportServices, DataApis } from '@carbon/pictograms-react';
import * as React from 'react';
export default {
  LocalImage,
  DesignAndDevelopment_02,
  BugVirusMalware,
  SupportServices,
  DataApis,
  React
};