import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Row, Grid, Column } from 'gatsby-theme-carbon/src/components/Grid';
import {
  footer,
  grid,
  nav,
  listItem,
  logo,
  content,
} from 'gatsby-theme-carbon/src/components/Footer/Footer.module.scss';

const Footer = ({ Content, links, Logo }) => {
  const { firstCol, secondCol } = links;
  return (
    <footer className={footer}>
      <Grid className={grid}>
        <Row>
          <Column colLg={2} colMd={2}>
            <ul className={nav}>
              {firstCol &&
                firstCol.map((link, i) => (
                  <li key={i} className={listItem}>
                    <p className="footerParagraph" aria-label={link.linkText}>
                      {link.linkText}
                    </p>
                  </li>
                ))}
            </ul>
          </Column>
          <Column colLg={2} colMd={2}>
            <ul className={nav}>
              {secondCol &&
                secondCol.map((link, i) => (
                  <li key={i} className={listItem}>
                    <a href={link.href} aria-label={link.linkText}>
                      {link.linkText}
                    </a>
                  </li>
                ))}
            </ul>
          </Column>
          <Column
            className={content}
            colLg={4}
            colMd={4}
            colSm={3}
            offsetLg={2}>
            <Content />
          </Column>
        </Row>
        <Row>
          <Column colLg={3}>
            <Logo />
          </Column>
        </Row>
      </Grid>
    </footer >
  );
};

const tdtLogo = () => {
  return (
    <>
      <img src="/images/tkd_logo_footer.webp" alt="TekDatum" width="460" height="auto" className={logo}></img>
    </>
  );
}

const DefaultContent = () => (
  <>
    <p>
      Merging <strong>AI</strong>, Project Management and  <strong>QA Practices</strong>
    </p>
    <p>
      &copy; {new Date().getFullYear()}
      <span> TekDatum </span>
    </p>
  </>
);

Footer.defaultProps = {
  links: {
    firstCol: [
      { linkText: "TekDatum Inc." },
      { linkText: "Houston" },
      { linkText: "Chicago" },
      { linkText: "Costa Rica" },
      /*{ linkText: '12333 Sowden RdSte B PMB 54556 Houston, Texas' },
      { linkText: '+1 (210) 595 9467' }*/
    ],
    secondCol: [
      { href: '/about', linkText: 'About Us' },
      { href: '/contact', linkText: 'Contact Us' },
      { href: '/privacyPolicy', linkText: 'Privacy Policy' },
    ],
  },
  Content: DefaultContent,
  Logo: tdtLogo,
};

Footer.propTypes = {
  /**
   * Specify the first and second columns of your links
   */
  links: PropTypes.exact({
    firstCol: PropTypes.arrayOf(
      PropTypes.shape({
        linkText: PropTypes.string,
      })
    ),
    secondCol: PropTypes.arrayOf(
      PropTypes.shape({
        href: PropTypes.string,
        linkText: PropTypes.string,
      })
    ),
  }),

  /**
   * Specify the first and second columns of your links
   */
  Content: PropTypes.elementType,

  /**
   * Provide a logo to be rendered in the bottom left corner
   */
  Logo: PropTypes.elementType,
};

export default Footer;