import React, { useState } from 'react';
import { useWindowScroll } from 'beautiful-react-hooks';
// import Utils from 'gatsby-theme-carbon/src/components/Utils';
import BackToTopBtn from 'gatsby-theme-carbon/src/components/BackToTopBtn';
import * as styles from 'gatsby-theme-carbon/src/components/Utils/Utils.module.scss';
import cx from 'classnames';



const CustomUtils = () => {
    const [hidden, setHidden] = useState(true);
    const onScroll = useWindowScroll();

    onScroll(() => {
        if (hidden && window.scrollY > 300) {
            setHidden(false);
        }
        if (!hidden && window.scrollY < 300) {
            setHidden(true);
        }
    });

    return (
        <div
            aria-label="This section contains customized utilities"
            className={cx(styles.container, { [styles.hidden]: hidden })}>
            <BackToTopBtn />
        </div>
    )
}

export default CustomUtils