// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-components-contact-js": () => import("./../../../src/pages/components/Contact.js" /* webpackChunkName: "component---src-pages-components-contact-js" */),
  "component---src-pages-contact-mdx": () => import("./../../../src/pages/contact.mdx" /* webpackChunkName: "component---src-pages-contact-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../../../src/pages/privacyPolicy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */),
  "component---src-pages-processes-ds-process-mdx": () => import("./../../../src/pages/processes/ds_process.mdx" /* webpackChunkName: "component---src-pages-processes-ds-process-mdx" */),
  "component---src-pages-processes-qa-process-mdx": () => import("./../../../src/pages/processes/qa_process.mdx" /* webpackChunkName: "component---src-pages-processes-qa-process-mdx" */),
  "component---src-pages-processes-software-dev-mdx": () => import("./../../../src/pages/processes/software_dev.mdx" /* webpackChunkName: "component---src-pages-processes-software-dev-mdx" */),
  "component---src-pages-services-data-labeling-mdx": () => import("./../../../src/pages/services/data_labeling.mdx" /* webpackChunkName: "component---src-pages-services-data-labeling-mdx" */),
  "component---src-pages-services-data-quality-mdx": () => import("./../../../src/pages/services/data_quality.mdx" /* webpackChunkName: "component---src-pages-services-data-quality-mdx" */),
  "component---src-pages-services-data-science-mdx": () => import("./../../../src/pages/services/data_science.mdx" /* webpackChunkName: "component---src-pages-services-data-science-mdx" */),
  "component---src-pages-services-local-image-js": () => import("./../../../src/pages/services/LocalImage.js" /* webpackChunkName: "component---src-pages-services-local-image-js" */),
  "component---src-pages-services-qa-automation-mdx": () => import("./../../../src/pages/services/qa_automation.mdx" /* webpackChunkName: "component---src-pages-services-qa-automation-mdx" */),
  "component---src-pages-services-software-dev-mdx": () => import("./../../../src/pages/services/software_dev.mdx" /* webpackChunkName: "component---src-pages-services-software-dev-mdx" */),
  "component---src-pages-work-mdx": () => import("./../../../src/pages/work.mdx" /* webpackChunkName: "component---src-pages-work-mdx" */)
}

